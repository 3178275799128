var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Status: "), _c('b-badge', {
    attrs: {
      "variant": _vm.status.warna
    }
  }, [_vm._v(" " + _vm._s(_vm.status.nama_status) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Tanggal Ajuan: "), _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(" " + _vm._s(_vm.konsumen.tanggal) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Kondisi: "), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.konsumen.kondisi_ket) + " ")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Nama Toko")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.nama_toko) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Nama Owner")]), _c('p', [_vm._v(_vm._s(_vm.konsumen.nama_owner))])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Bidang Bisnis")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.bisnis_fokus) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("No. HP Konsumen")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.no_hp) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d"
  }, [_vm._v("Alamat")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.alamat) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Jenis Konsumen")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.type_konsumen ? _vm.konsumen.type_konsumen.nama : "-") + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.tipe_bangunan) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Kepemilikan Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.kepemilikan_bangunan) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Lama Sewa Bangunan")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.lama_sewa) + " ")])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Keterangan")]), _c('p', [_vm._v(" " + _vm._s(_vm.konsumen.keterangan) + " ")])])], 1), _vm.canUpdateStatus ? _c('b-row', {
    staticClass: "justify-content-end"
  }, [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('footer', {
    staticClass: "d-flex justify-content-end align-items-center"
  }, [_c('b-form-select', {
    staticClass: "mr-2",
    attrs: {
      "options": _vm.statusOptions
    },
    model: {
      value: _vm.selectedStatus,
      callback: function callback($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.update($event);
      }
    }
  }, [_vm._v("Update Status")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }